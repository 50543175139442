import request from '@/utils/request'

//获取账单
export function GetAllBillList(params) {
  return request({
      url: `/api/user-wendu-log/all-bill-lists`,
      method: 'post',
      data: params
  })
}
//获取饭卡/温度卡明细
export function GetFankaList(params) {
  return request({
      url: `/api/user-fanka-log/fanka-bill-lists`,
      method: 'post',
      data: params
  })
}
//获取饭卡/温度卡余额
export function GetCancaInfo(params) {
  return request({
      url: `/api/user/canca-info`,
      method: 'get',
      params: params
  })
}
//获取商品详情
export function GetGoodsInfo(params) {
  return request({
      url: `/api/user-wendu-log/html-goods-info`,
      method: 'post',
      data: params
  })
}

